@import '../../vars.scss';

.Sidebar {
  width: $sidebarWidth !important;
  flex-shrink: 0 !important;

  .MuiDrawer-paper {
    width: $sidebarWidth !important;
    box-sizing: border-box !important;
  }

  @media (max-width: 600px) {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@import '../../vars.scss';

.Topbar {
  position: fixed !important;
  width: calc(100% - $sidebarWidth) !important;
  margin-left: $sidebarWidth !important;

  @media (max-width: 600px) {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
